import { Card, TableContainer } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';

type StyledTableContainerType = {
  theme: Theme;
};

const customBreadcrumbs = {
  margin: '35px 0',
};

const exportBox = {
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '30px 0',
};

const StyledTableContainer = styled(TableContainer)(({ theme }: StyledTableContainerType) => ({
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  borderRadius: '16px',

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('lg')]: {
    minWidth: '1200px',
  },
}));

const calendarBox = {
  padding: '16px',
};

const StyledSidebarWrapper = styled(Card)(({ theme }) => ({
  minWidth: '297px',
  maxWidth: '350px',
  padding: '24px',
  backgroundColor: theme.palette.background.default,
  flexBasis: '30%',
  [theme.breakpoints.down('lg')]: {
    minWidth: 'unset',
    maxWidth: '100%',
    flexBasis: '100%',
  },
}));

export { StyledSidebarWrapper, customBreadcrumbs, exportBox, StyledTableContainer, calendarBox };
